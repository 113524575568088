import React, { useEffect, useState, useMemo } from "react";
import { Box } from "@chakra-ui/react";
import { AnimatedContent } from "../components/RouteLink";
import { useTranslation } from "../core/hooks/useTranslation";
import Hero from "../views/SingleNews/Hero";
import Content from "../views/SingleNews/Content";

import newsDataLoader from "../content";

function SingleNews({ newsId }) {
  const { language } = useTranslation();
  const newsData = useMemo(() => newsDataLoader(language), [language]);

  const [loading, setLoading] = useState(true);
  const [article, setArticle] = useState(null);

  useEffect(() => {
    setTimeout(() => {
      // eslint-disable-next-line
      const newsInfo = newsData.find((news) => news.id == newsId);
      if (newsInfo) {
        setArticle(newsInfo);
        setLoading(false);
      }
    }, 1200);
  }, [newsId]);

  return (
    <Box>
      <Hero loading={loading} article={article} />
      <AnimatedContent>
        <Content loading={loading} article={article} />
      </AnimatedContent>
    </Box>
  );
}

export default SingleNews;
