import React, { useMemo } from "react";
import { Box, Heading } from "@chakra-ui/react";

// const blurEffect = "8px";

function Hero({ loading, article }) {
  const boxProps = useMemo(() => {
    if (loading || !article || !article.articleImage) {
      return {};
    }

    return {
      backgroundImage: article.articleImage,
      backgroundRepeat: "no-repeat",
      backgroundPosition: "center",
      backgroundSize: "cover",
    };
  }, [loading, article]);

  return (
    <Box
      minHeight={{ base: "16rem", md: "30rem" }}
      backgroundColor="dark.200"
      borderBottom="8px solid"
      borderColor="blue.600"
      position="relative"
      overflow="hidden"
    >
      <Box
        {...boxProps}
        position="absolute"
        zIndex="1"
        top={0}
        bottom={0}
        left={0}
        right={0}
        // filter={`blur(${blurEffect})`}
        // top={`-${blurEffect}`}
        // bottom={`-${blurEffect}`}
        // left={`-${blurEffect}`}
        // right={`-${blurEffect}`}
      />

      <Box
        position="absolute"
        zIndex="1"
        top={0}
        bottom={0}
        left={0}
        right={0}
        backgroundColor="rgba(0,0,0,0.75)"
      />

      <Box
        position="absolute"
        top="0"
        bottom="0"
        left="0"
        right="0"
        zIndex="2"
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        {article && article.articleTitle && (
          <Heading
            as="h1"
            textAlign="center"
            fontSize={{ base: "xl", md: "3xl", xl: "5xl" }}
            padding={{ base: "sm", lg: "xl" }}
            margin="0 auto"
            maxWidth={{ base: "800%", lg: "75%" }}
            color="white"
          >
            {article.articleTitle}
          </Heading>
        )}
      </Box>
    </Box>
  );
}

export default Hero;
