import React from "react";
import { Box, Stack, Skeleton, SkeletonText } from "@chakra-ui/react";

function Article({ loading, article }) {
  return (
    <Box
      paddingY="1.5rem"
      paddingX="2rem"
      borderRadius="0.5rem"
      boxShadow="0px 1px 10px 3px rgba(0,0,0,0.07)"
    >
      {loading ? (
        <Stack spacing="5">
          <SkeletonText noOfLines={7} spacing="2" />
          <SkeletonText noOfLines={3} spacing="2" />
          <SkeletonText noOfLines={4} spacing="2" />
          <Skeleton>
            <Box height="11rem" />
          </Skeleton>
          <SkeletonText noOfLines={9} spacing="2" />
          <Skeleton>
            <Box height="18rem" />
          </Skeleton>
          <SkeletonText noOfLines={3} spacing="2" />
          <SkeletonText noOfLines={4} spacing="2" />
          <SkeletonText noOfLines={2} spacing="2" />
          <SkeletonText noOfLines={5} spacing="2" />
        </Stack>
      ) : (
        <Box
          dangerouslySetInnerHTML={{
            __html: article.articleContent,
          }}
          css={{
            ul: {
              paddingLeft: "15px",
              margin: "10px 0",
            },
            img: {
              margin: "10px 5px",
            },
            h1: { fontWeight: "bold" },
            h2: { fontWeight: "bold" },
            h3: { fontWeight: "bold" },
            h4: { fontWeight: "bold" },
            h5: { fontWeight: "bold" },
            h6: { fontWeight: "bold" },
          }}
        />
      )}
    </Box>
  );
}

export default Article;
