import React from "react";
import { Box, Grid, GridItem } from "@chakra-ui/react";
import PageContainer from "../../../components/PageContainer";

import Author from "./Author";
import Article from "./Article";

function Hero({ loading, article }) {
  return (
    <Box paddingY="5rem">
      <PageContainer>
        <Grid
          templateColumns={{
            base: "repeat(1, 1fr)",
            lg: "repeat(3, 1fr)",
            xl: "repeat(4, 1fr)",
          }}
          columnGap={6}
          rowGap={6}
        >
          <GridItem>
            <Author loading={loading} article={article} />
          </GridItem>
          <GridItem
            colSpan={{
              base: 1,
              lg: 2,
              xl: 3,
            }}
          >
            <Article loading={loading} article={article} />
          </GridItem>
        </Grid>
      </PageContainer>
    </Box>
  );
}

export default Hero;
