import React from "react";
import {
  Box,
  Flex,
  Img,
  Heading,
  Text,
  SkeletonCircle,
  SkeletonText,
} from "@chakra-ui/react";
import moment from "moment";

function Author({ loading, article }) {
  return (
    <Box
      padding="0.75rem"
      borderRadius="0.5rem"
      boxShadow="0px 1px 10px 3px rgba(0,0,0,0.07)"
    >
      <Flex alignItems="center" justifyContent="flex-start">
        <Box
          overflow="hidden"
          marginRight="0.5rem"
          minHeight="2.5rem"
          minWidth="2.5rem"
        >
          {loading ? (
            <SkeletonCircle size="10" />
          ) : (
            <Img
              draggable={false}
              src={article.authorAvatar}
              height="2.5rem"
              width="2.5rem"
              borderRadius="50%"
              objectFit="cover"
            />
          )}
        </Box>

        <Box width="calc(100% - 2.5rem)">
          {loading ? (
            <SkeletonText noOfLines={2} spacing="3" />
          ) : (
            <>
              <Heading as="h6" fontSize="0.85rem" fontWeight="normal">
                {article.authorName}
              </Heading>
              <Text as="span" fontSize="0.7rem">
                {moment(article.articleDate).format("DD MMMM YYYY")}
              </Text>
            </>
          )}
        </Box>
      </Flex>
    </Box>
  );
}

export default Author;
